<!--
File: Report_6_ListOfSections.vue
Description: produce the list of DEUs, and Regions under RMD for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="selectedRegion"
            @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <DepsDropdown :label="$t('label.select_deu')" v-model="selectedDeu" :initial_value="selectedDeu"
            @input='reloadData' :region_id="selectedRegion" :disabled='!selectedRegion' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RoadClassesDropdown :label="$t('road_network.road_class')" v-model="selectedRoadClass"
            @input='reloadData' :initial_value=selectedRoadClass />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-datepicker v-model="selectedDate" md-immediately :md-open-on-focus="false">
            <label>{{ $t('label.select_date') }}</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }} </md-button>
          <md-button class="md-raised md-success" @click="exportToExcel" :disabled="btnDisabled">
            {{ $t('buttons.excel_export') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" :md-diameter="70" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedRegion" style="max-width: 200px;" :md-label="$t('road_network.region')"
            md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedDeu" :md-label="$t('road_network.dep')" md-sort-by="deu_description">
            {{ item.deu_description }}
          </md-table-cell>
          <md-table-cell style="min-width: 40%;" :md-label="$t('road_network.section_description')"
            md-sort-by="section_description">
            <a href='#' @click.stop.prevent='showSections(item)'> {{ item.section_description }}</a>
          </md-table-cell>
          <!--<md-table-cell :md-label="$t('inventory.start_place')" md-sort-by="start_place">
            {{ item.start_place }}
          </md-table-cell>
          <md-table-cell :md-label="$t('inventory.end_place')" md-sort-by="end_place">
            {{ item.end_place }}
          </md-table-cell>-->
          <md-table-cell :md-label="$t('road_network.start_km')" md-sort-by="start_distance_km" md-numeric>
            {{ item.start_distance_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.end_km')" md-sort-by="end_distance_km" md-numeric>
            {{ item.end_distance_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length_km')" md-sort-by="length_km" md-numeric>
            {{ item.length_km | numFormat(3) }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <md-table>
        <md-table-row>
          <md-table-head colspan="col_span">{{ $t('road_network.total_length') }}</md-table-head>
          <md-table-head md-numeric>{{ total_length }}</md-table-head>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
import RoadClassesDropdown from '../Dropdowns/RoadClassesDropdown.vue'
import { numFormat } from "@/store/refdata/format_helpers"
import { customSortMixin } from '@/mixins/customSortMixin'
import printReport from '@/mixins/printMixin'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  mixins: [customSortMixin, printReport],
  name: 'report-list-of-sections',
  data() {
    return {
      selectedRegion: null,
      selectedDeu: null,
      selectedRoadClass: null,
      selectedDate: new Date(),
      selectedRoad: '',
      btnDisabled: true,

      showSpinner: false,
      regionDescr: null,
      currentSort: 'region_description',
      currentSortOrder: 'asc'
    }
  },
  components: {
    RegionsDropdown,
    DepsDropdown,
    RoadClassesDropdown,
  },

  methods: {
    reloadData() {
      this.showSpinner = true
      this.btnDisabled = true
      const report_params = {
        region_id: this.selectedRegion,
        deu_id: this.selectedDeu,
        road_class: this.selectedRoadClass,
        selectedDate: this.selectedDate.toISOString().substring(0, 10)
      }
      this.$store.dispatch('REPORT_6_ROAD_SECTIONS', report_params).then(() => {
        this.btnDisabled = false
        this.showSpinner = false
      })
    },

    print() {
      this.showSpinner = true
      this.printReport(this.getPrintHeader, this.getTableHeaders, this.getTableRows)
      this.showSpinner = false
    },

    setWorkSheetHeader(worksheet) {
      let titleRow = worksheet.insertRow(1, [this.$t('label.reports_title')])
      titleRow.font = { bold: true, size: 16 };
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('A1:E1');

      titleRow = worksheet.insertRow(2, [
        `${this.$t('label.report6_title')} ${this.$t('label.as_on')} ${this.selectedDate.toLocaleDateString()}`
      ]);
      titleRow.font = { size: 14 };
      titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.mergeCells('A2:E2');

      if (this.headerTitles.length > 0) {
        titleRow = worksheet.insertRow(3, [this.headerTitles.join(', ')]);
        titleRow.font = { size: 14 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A3:E3');
      }
    },

    fillWorkSheet(workbook, workbookName) {
      const headerCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0070C0' } } // Blue
      const worksheet = workbook.addWorksheet(workbookName);

      // Set the table headers
      const columns = this.getWorksheetColumns
      worksheet.columns = columns

      // Apply formatting to the table headers first  row
      let newRow = worksheet.getRow(1);
      newRow.font = { bold: true, color: { argb: 'FFFFFFFF' } }
      newRow.fill = headerCellFill
      newRow.alignment = { vertical: 'middle', horizontal: 'center' }

      // Number formatting for the columns
      columns.forEach(item => {
        if (item.num) worksheet.getColumn(item.key).numFmt = '#,##0.000'
      })

      this.report.forEach(item => {
        worksheet.addRow(item);
      });

      const emptyCells = Array(this.col_span - 1).fill('');
      // Show the total
      const totalRow = worksheet.addRow([
        this.$t('label.total'),
        ...emptyCells,
        this.total_length
      ])
      totalRow.font = { bold: true, size: 12 }

      // Set the worksheet header, should be after filling the table
      this.setWorkSheetHeader(worksheet)
    },

    async exportToExcel() {
      // Create new Excel file
      const workbook = new ExcelJS.Workbook();
      const wbName = this.$t('label.report6_title')
      this.fillWorkSheet(workbook, wbName)

      // Save
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${wbName}.xlsx`);
    },

    showSections(item) {
      const hist = {
        selectedRegion: item.region_id,
        selectedDeu: item.deu_id,
        selectedRoad: item.road_id,
        perPage: 20,
        currentPage: 1
      }
      this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'sections' })
      this.$store.dispatch('SET_USE_HISTORY')
      this.$router.push('/inventory_data/sections')
    },
  },

  computed: {
    ...mapState({
      report_6_data: (state) => state.Reports.report_6_data,
    }),

    ...mapGetters(['report_6_total']),

    report() {
      return this.customSort(this.report_6_data
        .map(item => {
          return {
            ...item,
            deu_description: `${this.$t('road_network.dep')}-${item.deu_description}`
          }
        }), 'deu_description')
    },

    total_length() {
      return numFormat(this.report_6_total, 3)
    },

    getWorksheetColumns() {
      let res = [
        { header: this.$t('road_network.section_description'), key: 'section_description', width: 50 },
        { header: this.$t('road_network.start_km'), key: 'start_distance_km', width: 10, num: true },
        { header: this.$t('road_network.end_km'), key: 'end_distance_km', width: 10, num: true },
        { header: this.$t('road_network.length_km'), key: 'length_km', width: 10, num: true },
      ];

      if (!this.selectedRoadClass) {
        res.unshift({ header: this.$t('road_network.road_class'), key: 'road_class', width: 10 });
      }

      if (!this.selectedDeu) {
        res.unshift({ header: this.$t('road_network.dep'), key: 'deu_description', width: 10 });
      }

      if (!this.selectedRegion) {
        res.unshift({ header: this.$t('road_network.region'), key: 'region_description', width: 20 });
      }
      return res
    },

    headerTitles() {
      const titleFiltersArray = []

      if (this.selectedRegion) {
        titleFiltersArray.push(this.$t('label.from_region', { name: this.report[0].region_description }));
      }

      if (this.selectedDeu) {
        titleFiltersArray.push(`${this.$t('road_network.dep')}: ${this.report[0].deu_description}`);
      }

      if (this.selectedRoadClass) {
        titleFiltersArray.push(`${this.$t('road_network.road_class')}: ${this.report[0].road_class}`);
      }
      return titleFiltersArray
    },

    getPrintHeader() {
      const titleFilters = this.headerTitles.length > 0 ? `<br>(${this.headerTitles.join(', &nbsp;')})` : '';

      return `<h4 style='text-align:center'>${this.$t('label.reports_title')}</h4>
        <h4 style='text-align:center'>
          ${this.$t('label.report6_title')}
          ${this.$t('label.as_on')}${this.selectedDate.toLocaleDateString()}
          ${titleFilters}
        </h4>`;
    },

    getTableRows() {
      const columns = this.getWorksheetColumns

      const tableRowsArray = this.report.map(item => {
        return `<tr>${columns.map(col => col.num
          ? `<td class='numCell'>${numFormat(item[col.key])}</td>`
          : `<td>${item[col.key]}</td>`).join('')}
        </tr>`;
      });

      // Add totals
      tableRowsArray.push(`<tr>
          <th colspan=${this.col_span}>${this.$t('road_network.total_length')}</th>
          <th class='numCell'>${this.total_length}</th>
        </tr>`);

      return tableRowsArray.join('');
    },

    col_span() {
      return this.getWorksheetColumns.length - 1
    }
  },

  watch: {
    selectedDate(newValue) {
      if (!newValue) this.selectedDate = new Date()
      else this.reloadData()
    },
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>